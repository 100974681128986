@mixin override {
  * {
    &:focus {
      outline: none;
    }
  }

  .lc-map {
    @include desktop {
      margin-left: 230px;
    }
    .lc-mapContainer {
      background: white !important;
    }
  }

  .lc-board {
    width: 230px !important;
    font-size: 0.88em !important;
    margin: 0 !important;
    background: white !important;
    border-radius: 0;
    bottom: 0;

    @include desktop {
      max-height: unset;
    }

    @include mobile {
      width: unset !important;
      padding-bottom: 30px !important;
    }

    .lc-content {
      margin: 0 !important;
      border-radius: 0;
      box-shadow: initial !important;
      background: white !important;

      .lc-board-header {
        background-color: $primary !important;
        padding: 0 20px !important;
        border-radius: 0 !important;
        height: 55px !important;

        .lc-back {
          background: $primary !important;
          border: initial !important;
          margin-left: -15px;
          img {
            height: 20px !important;
          }
        }

        .lc-board-title {
          background: $primary !important;
          color: white !important;
          font-weight: initial !important;
          padding: 0 !important;
          white-space: initial !important;
          font-size: 1.1em !important;
          margin-bottom: 4px;
        }
      }
    }
  }

  .lc-board-head {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 0;

    img {
      width: 160px;
    }

    @include mobile {
      padding: 20px 0;
      img {
        width: 75px;
      }
    }
  }

  .lc-elevation {
    margin: 0;
    border-radius: 0 !important;
    font-size: 0.925em;
  }

  .menu-item-override {
    div {
      div {
        color: $secondary !important;
      }
    }

    &:last-child {
      border-radius: 0 !important;
    }
  }

  .lc-territory-title {
    color: $secondary !important;
  }
  .lc-territory-content {
    div {
      div {
        color: $secondary !important;
      }
    }
  }

  @include mobile {
    .lc-expanded-map {
      height: calc(var(--vh, 1vh) * 70) !important;
    }
  }
}
